import React from 'react';
import {GatsbyImage, getImage} from "gatsby-plugin-image";


const AnsprechpartnerItem = ({ item }) => {
  const {picture,name,position,phone,email} = item

  return (
    <>
      <GatsbyImage
        image={getImage(picture.localFile)}
        alt={picture.alternativeText}
        className=""
      />
      <h3 className={"h4 text-secondary pt-4"}>{name}</h3>
      <p className="pt-0 mt-0">{position}</p>
      <p className="pb-0 mb-0">Telefon: {phone}</p>
      <a href={"mailto:"+email}>{email}</a>
    </>
  );
}

export default AnsprechpartnerItem