import React from 'react';


import SubblockWrapper from "./subblock-wrapper";
import AnsprechpartnerList from "../ansprechpartner/ansprechpartnerList";

const SubblockAnsprechpartnerList =({list}) => {

  // if list ist empty then nothing to display
  if (list?.length === 0) return <></>

  return (
    <>
      <SubblockWrapper fullwidth colorTheme={"light"} position="center" >
        <AnsprechpartnerList content={list || []} />
      </SubblockWrapper>
    </>
  );
}

export default SubblockAnsprechpartnerList;