import React from "react";
import {Col, Row} from "react-bootstrap";
import AnsprechpartnerItem from "./ansprechpartnerItem";

const AnsprechpartnerList= ({content}) => {
  return (
    <>
      <Row>
        {content.map( (item) =>(
          <Col xs={12} lg={4} key={item.id}>
            <AnsprechpartnerItem item={item}/>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default AnsprechpartnerList