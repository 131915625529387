import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockPageHeader from "../components/common/subblock/subblock-page-header";
import SubblockHeadlineWithSubtitle from "../components/common/subblock/subblock-headline-with-subtilte";
import SubblockAnsprechpartner from "../components/common/subblock/subblock-ansprechpartner";

const Ansprechpartner = ({ data }) => {
  const header = data.strapiAnsprechpartner.header
  const title = data.strapiAnsprechpartner.title
  const subTitle = data.strapiAnsprechpartner.subTitle
  const ansprechpartnerList = data.strapiAnsprechpartner.ansprechpartnerList

  return (
    <>
      <Seo title="Ansprechpartner" />
      <Layout>
        <SubblockPageHeader content={header} />
        <SubblockHeadlineWithSubtitle title={title} subTitle={subTitle} noBottom />
        <SubblockAnsprechpartner list={ansprechpartnerList} />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query strapiAnsprechpartnerQuery {
  strapiAnsprechpartner {
    header {
      ...fragmentHeader
    }
    title
    subTitle
    ansprechpartnerList {
      id
      name
      position
      phone
      email
      picture {
        id
        mime
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}
`

export default Ansprechpartner
